import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import AOS from 'aos';
import "aos/dist/aos.css";
import Cookies from 'js-cookie';
import { MdOutlineScience } from "react-icons/md";
import { useSpring, animated } from 'react-spring';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Iframe from 'react-iframe'
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ModalWaitinglist from '../../utils/ModalWaitingList'
import ModalEnquireNow from '../../utils/ModalEnquireNow'
import TrustBoxContainer from '../../utils/Trustpilot';
import Trustpilot from './Trustpilot';
import Header from '../common/Header'
import HeadTags from "../common/HeadTags";
import SessionBlock from './SessionBlock';
import GalleryHome from './GalleryHome';
import VideoPopup from '../../utils/VideoPopup';
import ToastNotification from "../../utils/ToastNotification";
import FeedbackButton from '../../utils/FeedbackButton'
import { motion } from "framer-motion";
import Footer from '../common/Footer';
// import BannerSVG from '../common/BannerSvg'
// None	width: 100%;
// sm (640px)	max-width: 640px;
// md (768px)	max-width: 768px;
// lg (1024px)	max-width: 1024px;
// xl (1280px)	max-width: 1280px;
// 2xl (1536px)	max-width: 1536px;

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
]

const useCountUp = (end, duration = 2000) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const increment = end / (duration / 16);
        const timer = setInterval(() => {
            start += increment;
            if (start >= end) {
                clearInterval(timer);
                start = end;
            }
            setCount(Math.floor(start));
        }, 16);

        return () => clearInterval(timer);
    }, [end, duration]);

    return count;
};


const ClubHomepage = () => {
    const page = "home"

    useEffect(() => {
        AOS.init();
    }, [])

    const [message, setMessage] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        school: '',
        school_buying_role: '',
        message: '',
    });
    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/9ca578d3-3b87-495e-9b14-1238582b10bb';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                },
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: formData.firstName,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: formData.lastName,
                },
                {
                    objectTypeId: "0-1",
                    name: "school",
                    value: formData.school,
                },
                {
                    objectTypeId: "0-1",
                    name: "school_buying_role",
                    value: formData.school_buying_role,
                },
                {
                    objectTypeId: "0-1",
                    name: "message",
                    value: formData.message,
                },
                {
                    objectTypeId: "0-1",
                    name: "page",
                    value: "homepage",
                },
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };

        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage(true);
                console.log("sent")
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    const location = useLocation();

    useEffect(() => {
        // Check if there is an anchor in the URL
        const anchor = location.hash;

        if (anchor) {
            // Scroll to the element with the specified ID (remove the '#' character)
            const targetElement = document.getElementById(anchor.substring(1));

            if (targetElement) {
                // Smooth scroll to the target element
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);

    return (
        <>
            <HeadTags pageTitle="STEM Education for Kids - London" />
            <Header />
            {/* <div className="container-xl">
                <div className="z-0">
                    <div className="bg-kidshome-s xl:bg-kidshome h-full mx-auto bg-cover"> */}
            <section className="bg-gradient-to-r from-[#ff4b14] to-[#ffb143] bg-gradient-72 bg-cover bg-bottom bg-no-repeat h-[500px] relative font-synonym_medium ">ƒ
                <div className="bg-kidshome-s xl:bg-kidshome h-[500px] mx-auto bg-cover">
                    <div className="w-full max-w-8xl px-0 sm:px-0 md:px-0 lg:px-0 xl:px-0 2xl:px-0 mx-auto">
                        <div className="grid grid-cols-1 h-full px-0 sm:px-0 md:px-14 lg:px-0 xl:px-0 2xl:px-0">
                            <div className="flex items-center justify-center p-4 md:p-8 text-white w-full xl:w-3/4 lg:px-4 md:mt-16">
                                <div>
                                    <h1 className="text-3xl md:text-5xl font-chillax_semibold mt-16 md:mt-2 lg:mt-16">STEM After-School Club<br /><span className="text-education-black">for KS1 & KS2</span></h1>
                                    <p className="pt-4 pb-8 w-full md:w-[55%]">Ignite curiosity and foster a love for STEM (Science, Technology, Engineering, and Maths) education in primary school students
                                    </p>
                                    <a href="#theclub" className="p-4 text-education-black bg-education-white rounded-lg text-md font-synonym_medium">Explore The Space Club</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lg:py-0 relative bg-gray-50">
                <div className="w-full max-w-8xl px-0 md:px-14 lg:px-0 mx-auto">
                    <div className="rounded-2xl py-10 px-10 xl:py-16 xl:px-20  flex items-center justify-between flex-col gap-16 lg:flex-row">
                        <div className="w-full lg:w-60">
                            <h2 className="font-manrope text-4xl font-bold text-education-black mb-4 text-center lg:text-left font-chillax_medium">
                                Our Stats
                            </h2>
                            <p className="text-md text-gray-500 leading-6 text-center lg:text-left font-synonym_medium">
                                We can help you unleash the power of STEM in your school
                            </p>
                        </div>
                        <div className="w-full lg:w-4/5">
                            <div className="flex flex-col flex-1 gap-10 lg:gap-0 md:flex-row md:justify-between">
                                <div className="block">
                                    <div className="font-manrope font-bold text-4xl text-education-main mb-3 text-center lg:text-left font-chillax_medium">
                                        {useCountUp(500)}+
                                    </div>
                                    <span className="text-gray-900 text-center block lg:text-left font-synonym_medium">Total Students</span>
                                    <span className="text-gray-900 text-center block lg:text-left text-xs font-synonym_regular">KS1 & KS2</span>
                                </div>
                                <div className="block">
                                    <div className="font-manrope font-bold text-4xl text-education-main mb-3 text-center lg:text-left font-chillax_medium">
                                        {useCountUp(15)}+
                                    </div>
                                    <span className="text-gray-900 text-center block lg:text-left font-synonym_medium">Schools Engaged</span>
                                    <span className="text-gray-900 text-center block lg:text-left text-xs font-synonym_regular">Nationally!</span>
                                </div>
                                <div className="block">
                                    <div className="font-manrope font-bold text-4xl text-education-main mb-3 text-center lg:text-left font-chillax_medium">
                                        {useCountUp(100)}+
                                    </div>
                                    <span className="text-gray-900 text-center block lg:text-left font-synonym_medium">Hours teaching STEM</span>
                                    <span className="text-gray-900 text-center block lg:text-left text-xs font-synonym_regular">Week by week</span>
                                </div>
                                <div className="block">
                                    <div className="font-manrope font-bold text-4xl text-education-main mb-3 text-center lg:text-left font-chillax_medium">
                                        {useCountUp(4)}+
                                    </div>
                                    <span className="text-gray-900 text-center block lg:text-left font-synonym_medium">Satisfaction rate</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-12 relative" id="about">
                <div className="w-full max-w-8xl px-0 md:px-14 lg:px-14 mx-auto">
                    <div className="grid grid-cols-1 items-center text-center w-full mx-auto" data-aos="fade-up">
                        <p className="text-md text-gray-500 leading-6 text-center lg:text-center font-synonym_medium">
                            Get to Know Us
                        </p>
                        <h2 className="font-manrope text-4xl font-bold text-education-black mb-4 text-center lg:text-center font-chillax_semibold">
                            Transforming STEM Education
                        </h2>
                    </div>
                    <div className="grid grid-cols-2 xl:grid-cols-5 items-center text-center w-full mx-auto">
                        <div className="bg-education-ming/90 rounded-xl p-4 m-2 h-64 flex flex-col justify-between py-8" data-aos="fade-right">
                            <p className="text-education-white/90 font-synonym_medium">Years of experience</p>
                            <p className="text-7xl text-education-white/90 font-chillax_semibold">8+</p>
                        </div>
                        <div className="rounded-xl w-auto h-64 flex flex-col justify-between px-2" data-aos="fade-up-right">
                            <img src="assets/images/louno_club_gallery_0.jpg" alt="Louno Space Club" className="object-cover rounded-xl w-auto h-full" />
                        </div>
                        <div className="hidden rounded-xl w-auto h-64 xl:flex flex-col justify-between px-2" data-aos="fade-up-left">
                            <img src="assets/images/louno_space_workshop_1.webp" alt="Louno Space Club" className="object-cover rounded-xl h-full" />
                        </div>
                        <div className="rounded-xl w-auto h-64 flex flex-col justify-between px-2 col-span-2" data-aos="fade-left">
                            <img src="assets/images/louno_space_workshop_2.webp" alt="Louno Space Club" className="h-full object-cover rounded-xl" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 xl:grid-cols-5 items-center text-center w-full mx-auto" data-aos="fade-right">
                        <div className="hidden rounded-xl w-auto h-64 xl:flex flex-col justify-between px-2">
                            <img src="assets/images/louno_club_gallery_1.jpeg" alt="Louno Space Club" className="h-full object-cover rounded-xl" />
                        </div>
                        <div className="bg-education-pink-spanish col-span-2 rounded-xl p-4 m-2 min-h-64 md:py-12 md:h-64 flex flex-col justify-between" data-aos="fade-bottom-right">
                            <p className="text-education-black/90 text-xl font-synonym_medium">The importance of STEM</p>
                            <p className="text-education-black/90 font-synonym_regular">We understand the importance of fostering a love of learning in a way that's accessible, engaging, and tailored specifically for children aged 4-11. Through our dynamic workshops, your child will embark on exciting adventures that spark their curiosity about the universe, encourage them to collaborate with their peers, and empower them to dream big!
                            </p>
                        </div>
                        <div className=" rounded-xl w-auto h-64 flex flex-col justify-between px-2" data-aos="fade-bottom-left">
                            <img src="assets/images/louno_club_gallery_2.jpeg" alt="Louno Space Club" className="h-full object-cover rounded-xl" />
                        </div>
                        <a href="#contact" className="subtle-zoom">
                            <div className="bg-education-turquoise/80 rounded-xl p-4 m-2 h-64 flex flex-col justify-between py-8" data-aos="fade-left">
                                <p className="text-education-black/90 font-chillax_semibold text-xl sm:text-2xk">Are you ready<br />to join us?</p>
                                <p className="text-md sm:text-xl text-education-black/90 font-synonym_bold">Bring our Space Club to your school</p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>

            <section className="py-12 relative mx-auto bg-gray-50" id="theclub">
                <div className="w-full max-w-8xl px-0 md:px-14 lg:px-14 mx-auto">
                    <div className="grid grid-cols-1 items-center text-center w-full mx-auto">
                        <p className="text-md text-gray-500 leading-6 text-center lg:text-center font-synonym_medium" data-aos="fade-up">
                            Our Solution
                        </p>
                        <h2 className="font-manrope text-4xl font-bold text-education-black mb-4 text-center lg:text-center font-chillax_semibold" data-aos="fade-up">
                            The Space Club
                        </h2>
                        <p className="w-2/3 mx-auto font-synonym_medium" data-aos="fade-bottom">
                            Our club is designed to ignite curiosity and foster a love for STEM (Science, Technology, Engineering, and Mathematics) education among primary school students. Through a series of engaging sessions, we take young explorers on an exciting journey through the wonders of space.
                        </p>
                    </div>

                    <div className="flex flex-col ">
                        <div className="mt-6 pt-12">
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-4 lg:gap-24 items-center px-4 md:px-0 text-center md:text-left">
                                <div>
                                    <div data-aos="fade-up">
                                        <span className="text-gray-600 uppercase text-xs font-medium font-chillax_semibold" >
                                            Unlock Your Students' Potential
                                        </span>
                                        <p className="text-4xl mt-8 tracking-tighter font-semibold text-gray-700 text-balance font-chillax_semibold">
                                            Transform STEM Learning with Louno Space  Club
                                        </p>
                                        <p className="text-sm mt-4 text-gray-700 text-balance">
                                            Engage and inspire young minds with a hands-on after-school STEM club designed to make science and space exploration exciting.
                                        </p>
                                    </div>
                                    <div className="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
                                        <div data-aos="zoom-in">
                                            <div className="inline-flex items-center gap-2  text-xs text-gray-700"> <svg className="icon icon-tabler text-gray-700 size-4 icon-tabler-360" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M17 15.328c2.414 -.718 4 -1.94 4 -3.328c0 -2.21 -4.03 -4 -9 -4s-9 1.79 -9 4s4.03 4 9 4"></path>
                                                <path d="M9 13l3 3l-3 3"></path>
                                            </svg> <span className="text-gray-950 font-medium text-sm md:text-md"> Hands-On STEM Learning </span> </div>
                                        </div>
                                        <div data-aos="zoom-in">
                                            <div className="inline-flex items-center gap-2  text-xs text-gray-700"> <svg className="icon icon-tabler text-gray-700 size-4 icon-tabler-antenna-bars-3" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M6 18l0 -3"></path>
                                                <path d="M10 18l0 -6"></path>
                                                <path d="M14 18l0 .01"></path>
                                                <path d="M18 18l0 .01"></path>
                                            </svg> <span className="text-gray-950 font-medium text-sm md:text-md"> Real-World Space Applications </span> </div>
                                        </div>
                                        <div data-aos="zoom-in">
                                            <div className="inline-flex items-center gap-2  text-xs text-gray-700"> <svg className="icon icon-tabler text-gray-700 size-4 icon-tabler-load-balancer" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12 13m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                                <path d="M12 20m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                                <path d="M12 16v3"></path>
                                                <path d="M12 10v-7"></path>
                                                <path d="M9 6l3 -3l3 3"></path>
                                                <path d="M12 10v-7"></path>
                                                <path d="M9 6l3 -3l3 3"></path>
                                                <path d="M14.894 12.227l6.11 -2.224"></path>
                                                <path d="M17.159 8.21l3.845 1.793l-1.793 3.845"></path>
                                                <path d="M9.101 12.214l-6.075 -2.211"></path>
                                                <path d="M6.871 8.21l-3.845 1.793l1.793 3.845"></path>
                                            </svg> <span className="text-gray-950 font-medium text-sm md:text-md"> Led by Industry Professionals:<br />real-life space enegineers/scientists </span> </div>
                                        </div>
                                        <div data-aos="zoom-in">
                                            <div className="inline-flex items-center gap-2  text-xs text-gray-700"> <svg className="icon icon-tabler text-gray-700 size-4 icon-tabler-brand-speedtest" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M5.636 19.364a9 9 0 1 1 12.728 0"></path>
                                                <path d="M16 9l-4 4"></path>
                                            </svg> <span className="text-gray-950 font-medium text-sm md:text-md"> Problem-Solving & Creativity </span> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-full md:order-first"> <img src="assets/images/louno_club_gallery_3.jpeg" alt="#_" className="shadow-2xl rounded-2xl bg-gray-200 shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center" data-aos="fade-right" /> </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 mt-16 md:mt-32 md:gap-12 px-4 md:px-0 text-center md:text-left">
                        <div className="flex flex-col">
                            <div data-aos="fade-up">
                                <p className="text-md text-gray-500 leading-6  text-center md:text-left font-synonym_medium">
                                    The Space Club
                                </p>
                                <h2 className=" text-4xl font-bold text-education-black mb-4 text-center md:text-left font-chillax_semibold">
                                    Term Classes Structure and Overview
                                </h2>
                                <p className="mx-auto text-center md:text-left font-synonym_medium">
                                    Each session has a theoretical and interactive part between the students and the tutor (industry expert), followed by a heavy hands-on activity or experiment. Each lesson lasts one hour, and is inline with the National Curriculum!
                                </p>
                            </div>
                            <div className="relative pt-10" aos-data="zoom-in">
                                <p className="text-md font-synonym_semibold uppercase text-education-pink-barbie">
                                    Pro tip: We can adapt our curriculum to your needs!
                                </p>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="flex items-center justify-center">
                                <div className="overflow-x-auto">
                                    <table className="w-full bg-white shadow-xl rounded-xl border border-gray-200">
                                        <thead data-aos="fade-right">
                                            <tr className="border-b bg-education-main">
                                                <th className="px-6 py-4 text-left text-education-white font-synonym_medium">Year Group</th>
                                                <th className="px-6 py-4 text-left text-education-white font-synonym_medium">Subject</th>
                                                <th className="px-6 py-4 text-left text-education-white font-synonym_medium">Experiment/Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b" data-aos="fade-left">
                                                <td className="px-6 py-4 flex items-center gap-2">
                                                    <div>
                                                        <p className="text-gray-800 font-medium">Years<br />1 & 2</p>
                                                        <span className="text-education-main text-sm">(KS1)</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">Science (Forces & Materials), Art & Design, Maths	</td>
                                                <td className="px-6 py-4">Balloon Rockets - Students build simple balloon-powered rockets to understand force and motion.</td>
                                            </tr>
                                            <tr className="border-b" data-aos="fade-right">
                                                <td className="px-6 py-4 flex items-center">
                                                    <div>
                                                        <p className="text-gray-800 font-medium">Years<br />1 & 2</p>
                                                        <span className="text-education-main text-sm">(KS1)</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">Science (Light & Space), Art & Design	</td>
                                                <td className="px-6 py-4">Planetarium in a box - Students build a planetarium in a box with different constellations, to understand how light works.</td>
                                            </tr>
                                            <tr className="border-b" data-aos="fade-left">
                                                <td className="px-6 py-4 flex items-center">
                                                    <div>
                                                        <p className="text-gray-800 font-medium">Years<br />3 & 4</p>
                                                        <span className="text-education-main text-sm">(Lower KS2)</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">Science (Forces & Magnets), DT, Maths</td>
                                                <td className="px-6 py-4">Straw Rockets - Design and test small paper rockets, learning about aerodynamics and propulsion.</td>
                                            </tr>
                                            <tr className="border-b" data-aos="fade-right">
                                                <td className="px-6 py-4 flex items-center">
                                                    <div>
                                                        <p className="text-gray-800 font-medium">Years<br />3 & 4</p>
                                                        <span className="text-education-main text-sm">(Lower KS2)</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">Science (Electricity, Space), Computing</td>
                                                <td className="px-6 py-4">Simple Circuits in Space - Build basic circuits with batteries, bulbs, and switches to understand spacecraft power systems.</td>
                                            </tr>
                                            <tr className="border-b" data-aos="fade-left">
                                                <td className="px-6 py-4 flex items-center">
                                                    <div>
                                                        <p className="text-gray-800 font-medium">Years<br />5 & 6</p>
                                                        <span className="text-education-main text-sm">(Upper KS2)</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">Science (Physics, Chemistry, Earth & Space), Computing (Coding)</td>
                                                <td className="px-6 py-4">Design a Mars Rover with Micro:bits - Program a basic Mars Rover model using Micro:bits and sensors.</td>
                                            </tr>
                                            <tr className="border-b" data-aos="fade-right">
                                                <td className="px-6 py-4 flex items-center">
                                                    <div>
                                                        <p className="text-gray-800 font-medium">Years<br />5 & 6</p>
                                                        <span className="text-education-main text-sm">(Upper KS2)</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">Science (Space, Biology, Chemistry), PE</td>
                                                <td className="px-6 py-4">Astronaut Training - Simulating real astronaut tasks with reaction time tests, dexterity challenges, and fitness drills.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-32 relative mx-auto bg-education-space " id="testimonials">
                <div className="w-full max-w-8xl px-0 md:px-14 lg:px-14 mx-auto">
                    <div className="grid grid-cols-1 items-center text-center w-full mx-auto">
                        <p className="text-md text-education-main leading-6 text-center lg:text-center font-synonym_medium">
                            The Space Club
                        </p>
                        <h2 className="font-manrope text-4xl font-bold text-education-white mb-4 text-center lg:text-center font-chillax_semibold">
                            What parents and teachers are saying
                        </h2>
                    </div>
                    <Trustpilot />
                </div>
            </section>

            <section className="" id="partner">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="bg-education-main py-32">
                        <div className="w-full max-w-8xl px-4 md:px-14 lg:px-14 mx-auto">
                            <div className="bg-education-main">
                                <p className="font-medium text-education-ming font-chillax_medium">START A SPACE CLUB</p>
                                <h1 className="mt-2 text-2xl font-semibold text-education-space md:text-3xl font-chillax_semibold">
                                    Launch an Exciting STEM Club at Your School!
                                </h1>
                                <p className="mt-3 text-education-space font-synonym_medium pb-8">
                                    Inspire young minds with hands-on space and STEM activities.
                                    Whether you're a teacher or a parent, let’s bring Louno Space Club to your school!
                                </p>
                            </div>
                            <a href="#contact" className="font-chillax_medium text-lg p-2 bg-louno-white/80 shadow-lg rounded-lg text-education-space hover:ml-1 hover:duration-300">
                                Get In Touch
                            </a>
                        </div>
                    </div>

                    <div className="bg-education-ming py-32">
                        <div className="w-full max-w-8xl px-4 md:px-14 lg:px-14 mx-auto">
                            <p className="font-medium text-education-main font-chillax_medium">SPONSOR A CLUB</p>
                            <h1 className="mt-2 text-2xl font-semibold text-louno-white md:text-3xl font-chillax_semibold">
                                Invest in the Future of STEM Education 🚀
                            </h1>
                            <p className="mt-3 text-louno-white font-synonym_medium pb-8">
                                Support a Louno Space Club and make a lasting impact.
                                Give children access to high-quality STEM education while showcasing your commitment to innovation.
                            </p>
                            <a href="/partner" className="font-chillax_medium text-lg p-2 bg-louno-white/80 shadow-lg rounded-lg text-education-space hover:ml-1 hover:duration-300">
                                Partner with Us
                            </a>
                        </div>
                    </div>

                </div>
            </section>

            <section className="py-32 relative mx-auto bg-education-white" id="contact">
                <div className="w-full max-w-8xl px-4 md:px-14 lg:px-14 mx-auto">
                    <div className="grid grid-cols-1">
                        <p className="font-medium text-education-main font-chillax_medium">Contact us</p>
                        <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl  font-chillax_semibold">Chat to our friendly team</h1>
                        <p className="mt-3 text-gray-500  font-synonym_regular">We'd love to hear from you. Please fill out this form or shoot us an email.</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
                        <div className="grid grid-cols-2 gap-8">
                            <div>
                                <span className="inline-block p-3 text-education-main rounded-full bg-blue-100/80 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </span>

                                <h2 className="mt-4 font-medium text-lg text-gray-800  font-chillax_medium">Email</h2>
                                <p className="font-synonym_medium mt-2 text-md text-gray-500 ">Send us an email, we will reply within 48 hours.</p>
                                <p className="mt-2 text-md text-education-main"><a href="mailto:hi@louno.space">hi@louno.space</a></p>
                            </div>

                            <div>
                                <span className="inline-block p-3 text-education-main rounded-full bg-blue-100/80 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                </span>

                                <h2 className="mt-4 text-lg font-medium text-gray-800  font-chillax_medium">Live chat</h2>
                                <p className="font-synonym_medium mt-2 text-md text-gray-500 ">Start a chat if you need a quicker response.</p>
                                <p className="mt-2 text-md text-education-main"><a href="#hs-chat-open">Start new chat</a></p>
                            </div>

                            <div>
                                <span className="inline-block p-3 text-education-main rounded-full bg-blue-100/80 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                </span>

                                <h2 className="mt-4 text-lg font-medium text-gray-800  font-chillax_medium">Location</h2>
                                <p className="font-synonym_medium mt-2 text-md text-gray-500 ">Our clubs run nationally!</p>
                                <p className="mt-2 text-md text-education-main"><a href="#sessions">Come visit us</a></p>
                            </div>

                            <div>
                                <span className="inline-block p-3 text-education-main rounded-full bg-blue-100/80 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                </span>

                                <h2 className="mt-4 text-lg font-medium text-gray-800  font-chillax_medium">Phone</h2>
                                <p className="font-synonym_medium mt-2 text-md text-gray-500 ">Feel free to call us or send us a SMS. We're also on WhatsApp.</p>
                                <p className="mt-2 text-md text-education-main"><a href="tel:+4407873621402">07873 62 14 02</a></p>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="-mx-2 md:items-center md:flex">
                                    <div className="flex-1 px-2">
                                        <label className="block mb-2 text-md text-gray-600 font-synonym_medium">First Name <span className="text-education-main">*</span></label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            placeholder="John"
                                            required
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-education-main  focus:ring-education-main focus:outline-none focus:ring focus:ring-opacity-40 font-synonym_medium"
                                        />
                                    </div>

                                    <div className="flex-1 px-2 mt-4 md:mt-0">
                                        <label className="block mb-2 text-md text-gray-600  font-synonym_medium">Last Name <span className="text-education-main">*</span></label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            placeholder="Doe"
                                            required
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-education-main  focus:ring-education-main focus:outline-none focus:ring focus:ring-opacity-40 font-synonym_medium"
                                        />
                                    </div>
                                </div>

                                <div className="mt-4 -mx-2 md:items-center md:flex">
                                    <div className="flex-1 px-2">
                                        <label className="block mb-2 text-md text-gray-600 font-synonym_medium">School <span className="text-education-main">*</span></label>
                                        <input
                                            type="text"
                                            name="school"
                                            placeholder="Louno Space Primary School"
                                            required
                                            value={formData.school}
                                            onChange={handleChange}
                                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-education-main  focus:ring-education-main focus:outline-none focus:ring focus:ring-opacity-40 font-synonym_medium"
                                        />
                                    </div>

                                    <div className="flex-1 px-2 mt-4 md:mt-0">
                                        <label className="block mb-2 text-md text-gray-600 font-synonym_medium">Your Role <span className="text-education-main">*</span></label>
                                        <select
                                            name="school_buying_role"
                                            id="school_buying_role"
                                            className="text-gray-700 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                            required
                                            value={formData.school_buying_role}  // ✅ Controlled input
                                            onChange={handleChange} // ✅ Update state
                                        >
                                            <option value="">Choose your role</option>  {/* ✅ Remove `selected` */}
                                            <option value="headteacher">Headteacher</option>
                                            <option value="teacher">(Lead) Teacher</option>
                                            <option value="other">Office/Admin/Manager</option>
                                            <option value="governor">Governor</option>
                                            <option value="parent">Parent/Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <label className="block mb-2 text-md text-gray-600 font-synonym_medium ">Email address <span className="text-education-main">*</span></label>
                                    <input
                                        type="email"
                                        required
                                        name="email"
                                        placeholder="johndoe@example.com"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-education-main  focus:ring-education-main focus:outline-none focus:ring focus:ring-opacity-40 font-synonym_medium"
                                    />
                                </div>



                                <div className="w-full mt-4">
                                    <label className="block mb-2 text-md text-gray-600 font-synonym_medium">Message </label>
                                    <textarea
                                        name="message"
                                        placeholder="Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56  focus:border-education-main  focus:ring-education-main focus:outline-none focus:ring focus:ring-opacity-40  font-synonym_medium"
                                    ></textarea>
                                </div>
                                <div className="w-full mt-4">
                                    <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-education-main bg-gray-100 border-gray-300 rounded-sm focus:ring-education-main" required />
                                    <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 font-synonym_medium">By submitting this form, you confirm that you have read and agree to our <a href="/policies" className="text-education-main ">Privary Policy</a>.</label>

                                </div>

                                <button
                                    type="submit"
                                    className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-education-main rounded-lg hover:bg-education-pink-barbie focus:outline-none focus:ring focus:ring-education-pink-barbie focus:ring-opacity-50"
                                >
                                    Send message
                                </button>
                                {message && (
                                    <ToastNotification
                                        message="Thank you for getting in touch! We have received your email and will reach out soon."
                                        duration={5000}
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </div >
            </section >


            <Footer />
        </>
    );
}

export default ClubHomepage;
