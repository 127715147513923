import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Trash2 } from "lucide-react";
import Header from '../common/Header'
import Footer from '../common/Footer'
import HeadTags from "../common/HeadTags";
import Confetti from '../../utils/Confetti';

const modules = [
    { id: 1, name: "Solar Panel", mass: 5, volume: 3, power: -10, icon: "🌞" },
    { id: 2, name: "Camera", mass: 0.5, volume: 1.5, power: 1, icon: "📷", requires: "🖥️ Onboard Computer" },
    { id: 3, name: "Antenna", mass: 2, volume: 2.5, power: 4, icon: "📡", requires: "🔋 Battery" },
    { id: 4, name: "Battery", mass: 2.5, volume: 2, power: -5, icon: "🔋", },
    { id: 5, name: "Thruster", mass: 3, volume: 3, power: 4, icon: "🚀", requires: "🔋 Battery" },
    { id: 6, name: "Onboard Computer", mass: 2, volume: 2, power: 1, icon: "🖥️" },
    { id: 7, name: "Temperature Sensor", mass: 1, volume: 1, power: 1, icon: "🌡️", requires: "🖥️ Onboard Computer" },
    { id: 8, name: "Star Tracker", mass: 2, volume: 2, power: 2, icon: "🛰️", requires: "🖥️ Onboard Computer" },
    { id: 9, name: "Robotic Arm", mass: 5, volume: 4, power: 6, icon: "🛠️", requires: "🖥️ Onboard Computer" },
    { id: 10, name: "Earth Observation Sensor", mass: 3, volume: 3, power: 4, icon: "🌍", requires: "📷 Camera" },
];

const SatelliteBuilder = () => {
    const [selectedModules, setSelectedModules] = useState(() => {
        return JSON.parse(localStorage.getItem("selectedModules")) || [];
    });

    useEffect(() => {
        localStorage.setItem("selectedModules", JSON.stringify(selectedModules));
    }, [selectedModules]);

    const addModule = (module) => {
        setSelectedModules((prev) => [...prev, { ...module, uniqueId: Date.now() }]);
    };

    const removeModule = (uniqueId) => {
        setSelectedModules((prev) => prev.filter((mod) => mod.uniqueId !== uniqueId));
    };

    const totalMass = selectedModules.reduce((sum, mod) => sum + mod.mass, 0);
    const totalVolume = selectedModules.reduce((sum, mod) => sum + mod.volume, 0);
    const powerGenerated = selectedModules
        .filter(mod => mod.power < 0)
        .reduce((sum, mod) => sum + Math.abs(mod.power), 0);  // Convert to positive value

    const powerConsumed = selectedModules
        .filter(mod => mod.power > 0)
        .reduce((sum, mod) => sum + mod.power, 0);

    const isPowerBalanced = powerGenerated >= powerConsumed;

    const isSuccessful =
        totalMass > 0 && totalMass <= 20 &&
        totalVolume > 0 && totalVolume <= 20 &&
        isPowerBalanced; // Ensures power supply is sufficient

    const goalMass = 20
    const maxVolume = 20

    return (
        <>
            <HeadTags pageTitle="Louno Space - Build your Satellite" />
            <Header />
            {/* <div className="container-xl">
                <div className="z-0">
                    <div className="bg-kidshome-s xl:bg-kidshome h-full mx-auto bg-cover"> */}
            <section class="bg-white h-screen pt-24">
                <div class="grid max-w-screen-xl px-0 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div class="mr-auto place-self-center lg:col-span-7">
                        <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl font-chillax_semibold">
                            Build Your Satellite
                        </h1>
                        <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl font-synonym_medium">
                            Your mission: Build a satellite for Earth observation.<br /><br />
                            It must weigh no more than 20 kg<br />
                            It must have a total volume of 20 m³<br />
                            It must consume no more than 20 W
                        </p>
                        <a href="#startgame" class="inline-flex px-5 py-3 text-base font-medium text-gray-900 border border-gray-300 rounded-lg hover:bg-education-main focus:ring-4 focus:ring-gray-100 hover:text-education-white duration-300 font-synonym_medium uppercase">
                            Start game
                        </a>
                    </div>

                    <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                        <img src="/assets/images/Satellite.png" alt="mockup" />
                    </div>
                </div>
            </section>

            <section className="py-16 relative bg-gray-50" id="startgame">
                <div className="w-full max-w-8xl px-0 md:px-14 lg:px-0 mx-auto">
                    <div className="rounded-2xl px-10 xl:px-20 grid grid-cols-3">
                        <div className="w-full lg:w-60 columns-2">
                            <h2 class="max-w-2xl mb-4 text-4xl md:text-5xl xl:text-6xl font-chillax_semibold">
                                Objectives
                            </h2>
                        </div>
                        <div className="col-span-2 font-synonym_regular">
                            <p className={`mt-2 ${totalMass <= goalMass && totalMass > 0 ? 'text-gray-700' : 'text-gray-400'}`}>
                                {totalMass <= goalMass && totalMass > 0 ? '✔️' : '⚖️'} Total Mass of the satellite is less than {goalMass} kg.
                                <span className="font-synonym_medium"> It is currently {totalMass} kg.</span>
                            </p>

                            <p className={`${totalVolume <= maxVolume && totalVolume > 0 ? 'text-gray-700' : 'text-gray-400'}`}>
                                {totalVolume <= maxVolume && totalVolume > 0 ? '✔️' : '📦'} Total Volume: {totalVolume} m³
                            </p>

                            <p className={`${powerConsumed <= powerGenerated && powerConsumed > 0 ? 'text-gray-700' : 'text-gray-400'}`}>
                                {powerConsumed <= powerGenerated && powerConsumed > 0 ? '✔️' : '⚡'} Power Consumption: {powerConsumed} W
                            </p>
                            {isSuccessful && <Confetti />}

                            <p className="text-gray-700">🔋 Power Available: {powerGenerated} W</p>

                            {totalMass > 20 && <p className="text-red-500 font-semibold">⚠️ Too heavy for launch!</p>}
                            {totalVolume > 20 && <p className="text-red-500 font-semibold">⚠️ Too large to fit in rocket!</p>}
                            {!isPowerBalanced && <p className="text-red-500 font-semibold">⚠️ Not enough power! Add more solar panels or batteries.</p>}

                        </div>
                    </div>
                    <div className="w-full max-w-8xl px-0 md:px-14 lg:px-0 mx-auto">
                        <div className="rounded-2xl py-0 px-10 xl:px-20 grid grid-cols-2 gap-16">
                            <div>
                                <h2 className="font-bold text-lg mb-2">🛠 Available Modules</h2>
                                {modules.map((module) => (
                                    <motion.div key={module.id}
                                        className="p-4 m-2 border rounded-lg bg-white shadow-md flex items-center gap-3 cursor-pointer transition-all hover:scale-105"
                                        onClick={() => addModule(module)}
                                        whileHover={{ scale: 1.05 }}
                                        style={{ userSelect: "none" }}
                                    >
                                        <span className="text-xl">{module.icon}</span>
                                        <div>
                                            <p className="font-bold">{module.name}</p>
                                            <p className="text-sm text-gray-600">
                                                M: {module.mass}, V: {module.volume}, P: {module.power}
                                            </p>
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                            <div>
                                <motion.div className="min-h-[200px] rounded-lg bg-gray-50">
                                    <h2 className="font-bold text-lg mb-2">🛰️ Satellite Assembly</h2>
                                    {selectedModules.length > 0 ? (
                                        selectedModules.map((mod) => (
                                            <motion.div key={mod.uniqueId}
                                                className="p-3 m-2 bg-blue-200 rounded-lg shadow-md flex items-center justify-between w-full cursor-pointer"
                                                whileHover={{ scale: 1.05 }} onClick={() => removeModule(mod.uniqueId)}
                                            >
                                                <div className="flex items-center gap-3">
                                                    <span className="text-lg">{mod.icon}</span>
                                                    <p className="font-medium">{mod.name}</p>
                                                </div>
                                                <Trash2 size={20} className="text-red-500 hover:text-red-700" />
                                            </motion.div>
                                        ))
                                    ) : (
                                        <p className="text-gray-500 text-center">Click modules to add them to your satellite!</p>
                                    )}
                                </motion.div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default SatelliteBuilder;
