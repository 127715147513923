// Carousel.js
import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// NextArrow.js
// const NextArrow = (props) => {
//     const { className, onClick } = props;
//     return (
//         <div className={className} onClick={onClick}>
//             {/* Your custom next arrow content (e.g., an SVG or an icon) */}
//             <span>Next</span>
//         </div>
//     );
// };

// // PrevArrow.js
// const PrevArrow = (props) => {
//     const { className, onClick } = props;
//     return (
//         <div className={className} onClick={onClick}>
//             {/* Your custom previous arrow content (e.g., an SVG or an icon) */}
//             <span>Prev</span>
//         </div>
//     );
// };


const Carousel = () => {
    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // const FiveStars = (props) => {
    //     return (
    //         <>
    //             <div className="flex justify-center items-center space-x-1">
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
    //             </div>
    //         </>
    //     )
    // }

    // const FiveStarsS = (props) => {
    //     return (
    //         <>
    //             <div className="flex space-x-1">
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
    //                 <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
    //             </div>
    //         </>
    //     )
    // }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-1">
                {/* <div className="col-span-1 p-4">
                    <div className="bg-white shadow-md rounded-xl p-0 text-center items-center align-middle justify-center py-4 space-y-4">
                        <h3 className="font-chillax_medium text-4xl pb-2"> !</h3>
                        <h4 className="font-synonym_regular">Based on <a href="https://uk.trustpilot.com/review/louno.space" className="font-synonym_medium border-b-2 border-black" target="_blank">Truspilot reviews</a></h4>
                        <img src="https://cdn.worldvectorlogo.com/logos/trustpilot-1.svg" className="w-32 mx-auto" />
                    </div>
                </div> */}
                <div className="col-span-2">
                    <Slider {...settings}>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <p className="text-gray-500 py-4 text-sm">The future is learning about space exploration and this event covered so much in an easy to understand way, along with brilliant practical demos...</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Year 5, Teacher</h4>
                            </div>
                        </div>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <p className="text-gray-500 py-4 text-sm">My child loves Space Club! He comes home excited weekly, sharing what they’ve built and learned. It’s amazing to see him so engaged in STEM!</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Year 2, Parent</h4>
                            </div>
                        </div>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <p className="text-gray-500 py-4 text-sm">The hands-on activities are fantastic. My daughter loved designing a space habitat and coding for the first time! This club has given her so much confidence.</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Year 6, Parent</h4>
                            </div>
                        </div>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <p className="text-gray-500 py-4 text-sm">I always wondered where that passion behind "I want to become an astronaut" went... Thanks to the Louno Space Club I can see that again in my students!</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Year 4, Teacher</h4>
                            </div>
                        </div>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <p className="text-gray-500 py-4 text-sm">Even though space isn't the National Curriculum until Year 5, my class love the Space Club. The learn about STEM and have lots of fun with Arts & Crafts.</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Year 1, Teacher</h4>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Carousel;
