import { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid"; // Import close icon

const ToastNotification = ({ message, duration = 3000 }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (message) {
            setShow(true);
            const timer = setTimeout(() => {
                setShow(false);
            }, duration);

            return () => clearTimeout(timer); // Cleanup timeout
        }
    }, [message, duration]);

    return (
        show && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="relative flex items-center w-full max-w-xs p-4 text-education-white bg-education-main  rounded-lg shadow-xl">
                    {/* Icon */}
                    <svg className="w-5 h-5 text-education-white rotate-45" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9" />
                    </svg>

                    {/* Message */}
                    <div className="ps-4 text-sm font-normal">{message}</div>

                    {/* Close Button */}
                    <button
                        onClick={() => setShow(false)}
                        className="absolute top-2 right-2 text-education-white hover:text-education-white transition"
                    >
                        <XMarkIcon className="w-5 h-5" />
                    </button>
                </div>
            </div>
        )
    );
};

export default ToastNotification;
