import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { HeartIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import HeadTags from "./HeadTags";

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 1440);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenuAbout = () => {
        setIsMobile(prevState => !prevState);
    };

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const [isOpenAbout, setIsOpenAbout] = useState(false);

    const toggleDropdownAbout = () => {
        setIsOpenAbout(!isOpenAbout);
    };



    return (<>
        {/* Order HTML: general     sm      md      lg      xl      2xl */}
        {/* <div className="bg-education-pink-barbie">
            <div className="p-4 text-education-white text-center mx-auto">
                <p className="font-synonym_medium">Bring a sibling or a friend for a 10% discount! Use code 'MOONLANDING' at checkout 🚀</p>
            </div>
        </div> */}
        <section className="relative bg-education-white py-2">
            <div className="w-full max-w-8xl px-0 md:px-14 lg:px-0 mx-auto">
                <div className="px-0 py-02 text-education-black items-center mx-auto max-w-8xl">
                    <div className="px-10 xl:px-16 flex justify-between items-center">
                        <div className="flex items-center">
                            <a href="/">
                                <img src="/assets/images/Louno_Space_logo.png" className="w-48" alt="Logo" />
                            </a>
                        </div>
                        {isMobile ? (
                            <button className="focus:outline-none" onClick={() => setIsNavOpen((prev) => !prev)}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                                </svg>
                            </button>
                        ) : (
                            <div className="flex gap-x-16 items-center">
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Home
                                </a>
                                <a href="/#about" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    About
                                </a>
                                <a href="/#theclub" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    The Space Club
                                </a>
                                {/* <a href="/birthday-party-kids" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Birthday Party 🎈
                                </a> */}
                                <a href="https://adventure.louno.space" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Blog
                                </a>
                                <a href="#contact" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Contact
                                </a>
                                <a href="/partner" class="self-end text-education-space font-semibold rounded-lg text-sm px-2 py-2.5 text-center me-2 mb-2 mt-2 border-education-main border-2 hover:bg-education-main hover:text-white duration-400">
                                    Partner with Us</a>
                            </div>
                        )}
                    </div>
                    {isMobile && (
                        <div className={`${isMobile && isNavOpen ? 'block z-20 h-auto' : 'hidden'}`}>
                            {/* Mobile menu content goes here */}
                            <div className="px-6 py-8 bg-white text-center z-20 h-auto">
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Home
                                </a>
                                <a href="/#about" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    About
                                </a>
                                <a href="/#theclub" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    The Space Club
                                </a>
                                {/* <a href="/birthday-party-kids" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Birthday Party 🎈
                                </a> */}
                                <a href="https://adventure.louno.space" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Blog
                                </a>
                                <a href="#contact" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Contact
                                </a>
                                <a href="/partner" class="self-end text-education-space font-semibold rounded-lg text-sm px-2 py-2.5 text-center me-2 mb-2 mt-2 border-education-main border-2 hover:bg-education-main hover:text-white duration-400">
                                    Partner with Us</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </>
    );
}

export default Header;
