import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoaderSmall from '../common/LoaderSmall';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";

const Footer = () => {
	const [emailAddress, setEmailAddress] = useState("")
	const [status, setStatus] = useState('');
	const [OpenParents, setOpenParents] = useState(false);
	const [OpenTeacher, setOpenTeacher] = useState(false);
	const [message, setMessage] = useState('');

	function formv3() {
		// Create the new request 
		var xhr = new XMLHttpRequest();
		var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/b4ffc428-9e55-4730-aebb-d12e93b7467c'
		// Example request JSON:
		var data = {
			"submittedAt": Date.now(),
			"fields": [
				{
					"objectTypeId": "0-1",
					"name": "email",
					"value": emailAddress
				},
			],
			"context": {
				"hutk": Cookies.get('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
			}
		}

		var final_data = JSON.stringify(data)

		xhr.open('POST', url);
		// Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
		xhr.setRequestHeader('Content-Type', 'application/json');

		xhr.onreadystatechange = function () {
			if (xhr.readyState == 4 && xhr.status == 200) {
				setMessage("Subscribed. You will receive a coupon code within 24 hours. Thank you 🚀")
			} else if (xhr.readyState == 4 && xhr.status == 400) {
				console.log(xhr.responseText); // Returns a 400 error the submission is rejected.          
			} else if (xhr.readyState == 4 && xhr.status == 403) {
				console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
			} else if (xhr.readyState == 4 && xhr.status == 404) {
				console.log(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
			}
		}

		// Sends the request
		xhr.send(final_data)
		//console.log("Cookie" + Cookies.get("hubspotutk"))
	}

	const handleChangeEmailAddress = (event) => {
		const value = event.target.value;
		setEmailAddress(value);
	};

	return (
		<>
			<footer aria-label="Site Footer" className="bg-education-white relative">
				<div class="relative isolate overflow-hidden py-8 sm:py-12 mx-auto max-w-7xl">
					<div class="px-6 lg:px-8">
						{/* <div className="my-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">
							<div className="col-span-2">
								<div>
									<h2 className="text-2xl font-bold font-chillax_medium text-gray-900">
										Get the latest news!
									</h2>

									<p className="mt-4 text-gray-500">
										Stay in the loop! Subscribe to our newsletter now and never miss an update.
									</p>
								</div>
							</div>
							<div className="col-span-2 lg:col-span-3 lg:flex lg:items-end">
								<form className="w-full">
									<label htmlFor="UserEmail" className="sr-only"> Email </label>

									<div className="border border-gray-100 p-2  sm:flex sm:items-center sm:gap-4">
										<input type="email" id="UserEmail" placeholder="yourname@louno.space"
											className="z-0 w-full border-none focus:border-transparent focus:ring-transparent sm:text-sm rounded-md h-11 pl-4"
											onChange={handleChangeEmailAddress} value={emailAddress} />

										<button
											className="mt-1 w-full bg-education-main px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-education-main sm:mt-0 sm:w-auto sm:flex-shrink-0 rounded-md focus:border-transparent"
											onClick={formv3}>
											Sign Up
										</button>
									</div>
								</form>
							</div>
						</div> */}
						<div className="grid grid-cols-2 md:grid-cols-2 gap-8 lg:mt-16 lg:grid-cols-4 lg:gap-y-16 ">


							<div className="col-span-2 md:col-span-1 flex flex-col items-center justify-center text-center md:items-start md:text-left md:justify-start h-full">
								<p className="font-medium text-gray-500 font-synonym_regular text-center md:text-left w-full flex flex-col items-center md:items-start">
									<img
										src="/assets/images/Space-Dark_NoBG.png"
										className="mb-2 w-48 mx-auto md:mx-0"  // ✅ Centers on mobile, left on desktop
										alt="Louno Space Logo"
									/>
									Inspiring the next generations of space explorers, join us!
								</p>
							</div>



							<div className="sm:col-span-1">
								<p className="font-medium text-gray-900">Education</p>

								<nav aria-label="Footer Navigation - Education" className="mt-6">
									<ul className="space-y-4 text-sm">
										<li>
											<a className="text-gray-700 transition hover:opacity-75" href="/#about">About</a>
										</li>
										<li>
											<a className="text-gray-700 transition hover:opacity-75" href="/#theclub">The Space Club</a>
										</li>
									</ul>
								</nav>
							</div>


							<div className="sm:col-span-1">
								<p className="font-medium text-gray-900">Company</p>
								<nav aria-label="Footer Navigation - Meet us" className="mt-6">
									<ul className="space-y-4 text-sm">
										<li><a className="text-gray-700 transition hover:opacity-75" href="/#contact">Launch a STEM Club</a></li>
										<li><a className="text-gray-700 transition hover:opacity-75" href="/partner">Partner with Us</a></li>
									</ul>
								</nav>
							</div>

							<div className="col-span-1 sm:col-span-1">
								<p className="font-medium text-gray-900">Our Supporters</p>

								<nav aria-label="Footer Navigation - Supporters" className="mt-6">
									<ul className="space-y-4 text-sm">
										<li>
											<a href="#" className="text-gray-700 transition hover:opacity-75">
												<img src="/assets/images/uksa.svg" className="w-48" />
											</a>
										</li>
									</ul>
									<ul
										className="flex gap-x-4 md:gap-x-10 mt-4 pb-4"
									>
										<li>
											<a
												href="https://www.facebook.com/lounospace"
												rel="noreferrer"
												target="_blank"
												className="text-gray-700 transition hover:opacity-75"
											>
												<span className="sr-only">Facebook</span>

												<svg
													className="h-6 w-6"
													fill="currentColor"
													viewBox="0 0 24 24"
													aria-hidden="true"
												>
													<path
														fillRule="evenodd"
														d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
														clipRule="evenodd"
													/>
												</svg>
											</a>
										</li>
										<li>
											<a
												href="https://www.instagram.com/lounospace/"
												rel="noreferrer"
												target="_blank"
												className="text-gray-700 transition hover:opacity-75"
											>
												<span className="sr-only">Instagram</span>

												<svg
													className="h-6 w-6"
													fill="currentColor"
													viewBox="0 0 24 24"
													aria-hidden="true"
												>
													<path
														fillRule="evenodd"
														d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
														clipRule="evenodd"
													/>
												</svg>
											</a>
										</li>
										<li>
											<a
												href="https://www.tiktok.com/@lounospace"
												rel="noreferrer"
												target="_blank"
												className="text-gray-700 transition hover:opacity-75"
											>
												<span className="sr-only">TikTok</span>

												<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABgUlEQVR4nGNgGAWkAHOHgIPmjoH/KcFmjoH7cVtAoeHmEAt+D24LnH1j/te39v8vr2qhjQWRSfn/QeDAwWP/rZyDMS1wCPhDFQtAYOmKDf/t3MNJsuAfsRb8/PkLTF+/eed/QVENZRZYuwT/9wtP/e8VGA+3YOPajf83bNzx//jx0/9ratvJtyAoKuP/qzdv/j94+Ph/XW073IL5sxeC5R29Iv87eUch9DgE/CXaAhffmP+/fv/+f/TIif8b1m3+n51XhWEBBsZrgUPAX2TFHX0z/l+6fP1/f+9UjDiYP2cR5Ras2rD1/917D/4nphbCxRIySv9//frt/5wZ8ym3oLljMti16zfv/G/tGgrGh46d+n/61HlwfOBIaf+ItsA7NPn/p09fwJZ8/f79/++/f8E+OrhjN9gyki0AJTGMdJ+Y93/T5p3/T5069//IwaP/Vy5e+d/dPxZfXiHNAnModvCM+G/rHkZMgUeeBeYkYNwWOAYeoNgCh4B9OC0YBQxYAACWS5e/dWqAcgAAAABJRU5ErkJggg==" />
											</a>
										</li>
										<li>
											<a href="https://www.linkedin.com/company/lounospace/"
												rel="noreferrer"
												target="_blank"
												className="text-gray-700 transition hover:opacity-75"
											>
												<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+ElEQVR4nO2WPWsVURCGR2MhWNhYCqKxVkjizBqQM29MOqMzV9dWtNO/ICathYKtf0FQtA3YGMRKCxU7EcRKFCFq/Aio7BpvIXdvTj7IPXs5D0xzubPsw5x39hBlMttDEWYPM/wWw18x7HNVovaSYTeOo3OA2oDALzLsm8B/9yz1Lxz8HKWMhDMq8F+NEt2yn8WUj1GqsNrC2hJ/i9XvUqoI/FOsiKh/oFRhtY/RE4G9p1QR+P34idg9SpViyseqIK89DV85Fs5OUMoIrMPwpX7rt4CdpzbAwfcz7DrDn3c/iLAXrfogZlKC1R/Hr19f3IresixHitCZro6twJ4I/C2rf2fYMsPfsNoDgV0eny73RotEr97V2kxvCGGXqF8RtdeR8kvV/4loR1IiDHu23h6pr0a2cGRmZk8yIpsqtYchXNjdfhHUR+3msIisMPxo60WkPmJ+Z0hE7Md4OLWv/SKot9+loRAR+O3Biqh/Ffi1iZOdQzQ/v3MSNspqc6u/x09E7engRKqX1dPSK6ccvKiuJ+sQeTdAEbvaS6IrozYXLQJbHpgIn5g92E9kEja60ffYVpHqxttPpCzLkVaI9JP4RxapyBOhfLQakRx2yhmpyeu3AckZoZyRmpyRBiRnhIYoIwxfjH6A+qMN9f7X1wRv8fMymQzRH9qgignbwCleAAAAAElFTkSuQmCC" width="24" />
											</a>
										</li>
										<li>
											<a href="https://www.youtube.com/@lounospace"
												rel="noreferrer"
												target="_blank"
												className="text-gray-700 transition hover:opacity-75"
											>
												<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABZklEQVR4nO3UP0hVYRgG8J/4d7BNgiRwFHKKEJHASBoidQhKMMJFCkwaJNSIQImSCkoclKIpxAIhmhIHHaTJocFRHBIVQg0cBGe58CKHw71X4Z7BwQc+zvs93/vn8L7f93COs4YKNKETTzGOCXzGDOZifQnuPUbxKGKuFEvegX9YxU98xCAe4yHu4zbuhp3jn+AlPmE+4hdRm05+Af/RUmIHqvENr9IHbViSDW7my9UbPS2E17h0ygKXsZUmhzBWJGgDu3iBmhMKlOEwvscYiyLFCjRiMuzedIIUdtKDfoeBEwrUhX0Vy/iNawX811FfaoEVtBbw/4uLSSL3WEYybNE+qpLEM7zJaMjlOEiTPfiR0TVtwFqabI7BZIF2LOR74nshWKWgEt/xPN/hDWziTziNh5h14x5uJVZXCF4fhjGFX9jGbPxwwQHlJPcO+qP3H0JGvibkOrefxtsY/ANcT1zlc5whHAEEg0vMgMaMPgAAAABJRU5ErkJggg==" alt="youtube-play--v1" width="24" />
											</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>

					<div className="mt-0 border-t border-gray-100 pt-8 w-max-7xl px-6 text-center">
						<div className="sm:flex sm:justify-between">
							<p className="text-xs text-gray-500">
								&copy; 2025. Louno Space Ltd. All rights reserved.
							</p>

							<nav aria-label="Footer Navigation - Support" className="mt-8 sm:mt-0">
								<ul className="flex flex-wrap justify-center gap-4 text-xs lg:justify-end">
									<li>
										<a href="/policies" className="text-gray-500 transition hover:opacity-75">
											Terms & Conditions
										</a>
									</li>

									<li>
										<a href="/policies" className="text-gray-500 transition hover:opacity-75">
											Privacy Policy
										</a>
									</li>

									<li>
										<a href="/policies" className="text-gray-500 transition hover:opacity-75">
											Cookies
										</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>

			</footer>
		</>
	);
}

export default Footer;
