import React, { useState, useEffect } from 'react';
// import { QRCode } from 'react-qrcode-logo';
// import AOS from 'aos';
import "aos/dist/aos.css";
// import Cookies from 'js-cookie';
// import { MdOutlineScience } from "react-icons/md";
// import { useSpring, animated } from 'react-spring';
// import { Dialog } from '@headlessui/react'
// import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
// import Iframe from 'react-iframe'
// import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
// import ModalWaitinglist from '../../utils/ModalWaitingList'
// import TrustBoxContainer from '../../utils/Trustpilot';
import Footer from '../common/Footer';
import Header from '../common/Header';
import Slider from "react-slick";
import Loader from '../common/Loader';
import FeedbackButton from '../../utils/FeedbackButton'

const SpaceToInspire = () => {
    // const [isMobile, setIsMobile] = useState(false);
    // const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            // ReactGA.initialize('G-66C9LPYT24');
            // ReactGA.pageview(window.location.pathname + window.location.search);
            // Smartlook.init('14545ea10544e1c6c276274490b06623c48a6a74')
        },
        500);
    }, []);

    // useEffect(() => {
    //     const checkScreenWidth = () => {
    //         setIsMobile(window.innerWidth <= 768);
    //     };

    //     checkScreenWidth();

    //     window.addEventListener('resize', checkScreenWidth);

    //     return () => {
    //         window.removeEventListener('resize', checkScreenWidth);
    //     };
    // }, []);

    var settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            {!loading ?
                <>
                    <div className="bg-white overflow-y-hidden">
                        <Header />
                        <FeedbackButton page="space-to-inspire"/>
                        <div className="starscontainer h-auto py-32 relative font-synonym_medium">
                            <div className="h-full mx-auto bg-cover">
                                <div className="grid grid-cols-1 h-full text-center">
                                    <div id="stars"></div>
                                    <div id="stars2"></div>
                                    <div id="stars3"></div>
                                    <div className="text-education-white">
                                        <h1 className="text-3xl md:text-5xl font-chillax_semibold w-full md:w-2/4 mx-auto px-6 md:px-0">Space to Inspire: Unlocking the Wonder and Power of STEM</h1>
                                        {/* <p className="pt-4 pb-8 w-[60%] mx-auto">British Science Week is a ten-day celebration of science, technology, engineering and maths that will place between 8 - 17 March 2024!</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="relative isolate overflow-hidden bg-white py-12 sm:py-24 mx-auto max-w-7xl" id="about">
                            <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                                <div className="mr-0 md:mr-4">
                                    <div class="mx-auto max-w-2xl lg:mx-0 col-span-1 w-full">
                                        <h1 class="text-4xl font-bold tracking-tight text-education-space sm:text-5xl font-chillax_medium">Space as a way to Inspire</h1>
                                        <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                            <span className="font-synonym_medium text-education-black">Space ignites a child's natural curiosity and wonder about the universe.</span> It's a place of endless possibilities that sparks their imagination and makes them ask big questions. But research shows a decline in children's interest in STEM (Science, Technology, Engineering, and Math) by age 10. This is a critical time to nurture their curiosity and set them on a path of lifelong learning.
                                            <br /><br />
                                            <span className="font-synonym_medium text-education-black">That's where Louno Space comes in</span>. We believe <span className="font-synonym_medium text-education-black">every child has the potential to be a future astronaut or STEM leader</span>. We use the power of space exploration to ignite a <span className="font-synonym_medium text-education-black">lifelong passion for STEM</span> in children aged 4-9.
                                            <br /><br />
                                            <span className="font-synonym_medium text-education-black">Our workshops are packed with hands-on activities and exciting adventures</span> that make learning about STEM fun and engaging. We help them build rockets, train like astronauts, and discover the wonders of the universe – all while fostering collaboration, critical thinking, and problem-solving skills.
                                        </p>
                                    </div>
                                </div>
                                <div className="ml-0 md:ml-8 mt-16 md:mt-0">
                                    <Slider {...settings}>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_1.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_2.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_3.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_4.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_5.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_6.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_7.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_8.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_9.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_10.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                        <div>
                                            <img src="/assets/images/louno_space_to_inspire_11.webp" className="rounded-3xl" alt="Louno Space"/>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div >
                    <Footer />
                </>
                : <Loader />
            }
        </>
    );
}

export default SpaceToInspire;