import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/OldHeader';
import Footer from '../common/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from '../common/Loader';
import { TabTitle } from "../../utils/General";
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ModalWaitinglist from '../../utils/ModalWaitingList';

const SessionBlock = ({ iconhome, icon, name, courseinfo, agegroup, date, location, starttime, endtime, fullybooked, booknow, booknowlink, notifyme, waitinglist, camp, label, cost, page, promo }) => {

    const [modalDataNotify, setModalDataNotify] = useState({
        isOpen: false,
        dateWorkshop: null,
        workshop: null,
        // Add other modal data as needed
    });

    const [modalDataWait, setModalDataWait] = useState({
        isOpen: false,
        dateWorkshop: null,
        workshop: null,
        // Add other modal data as needed
    });

    const openModalNotify = (date) => {
        setModalDataNotify({
            isOpen: true,
            dateWorkshop: date,
            // Set other modal data as needed
        });
    };

    const closeModalNotify = () => {
        setModalDataNotify({
            isOpen: false,
            dateWorkshop: '',
            workshop: ''
            // Set other modal data as needed
        });
    };

    const openModalWait = (date) => {
        setModalDataWait({
            isOpen: true,
            dateWorkshop: date,
            // Set other modal data as needed
        });
    };

    const closeModalWait = () => {
        setModalDataWait({
            isOpen: false,
            dateWorkshop: '',
            workshop: ''
            // Set other modal data as needed
        });
    };

    return (
        <>
            <div className={`hidden md:grid z-0 grid-cols-1 md:grid-cols-6 pl-5 mt-2 p-8 rounded-lg ${waitinglist || notifyme ? 'bg-education-white/80' : 'bg-education-white'}`}  >
                <div className="md:col-span-3 flex items-center">
                    {icon && <div dangerouslySetInnerHTML={{ __html: icon }} />}
                    {iconhome && iconhome}
                    <div className="ml-8 pl-8 border-l-2 border-education-main">
                        <h3 className="font-synonym_medium leading-5 tracking-wide text-lg">{name} {fullybooked && "- FULLY BOOKED"}</h3>
                        <p className="text-gray-500 font-synonym_regular text-md mt-4">
                            <span className="text-gray-800 font-synonym_medium">Age group</span> {agegroup}<br />
                            <span className="text-gray-800 font-synonym_medium">Date</span> <span className="ml-4 text-gray-800">{date}</span> <br />
                            <span className="text-gray-800 font-synonym_medium">Location</span> {location} <br />
                            <span className="text-gray-800 font-synonym_medium">Duration</span> From {starttime} - {endtime}
                            <br /><br />
                            {label === "cheaper" && <><span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Quick Session</span> <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Budget-Friendly</span> <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Compact Workshop</span> </>}
                            {label === "longer" && <><span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">In-Depth Workshop</span> <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Full-Day</span> <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Comprehensive Experience</span> </>}
                        </p>
                    </div>
                </div>
                <div className="my-8 md:my-0 text-left md:tet-left md:col-span-1"></div>
                <div className="md:col-span-2 items-center justify-center grid grid-cols-1 text-center">
                    {/* <div>
                        {!camp && <a href={courseinfo} className="border-2 border-education-main-text p-2 text-education-main-text rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-main-text duration-200">Course info <span aria-hidden="true">&rarr;</span></a>}
                    </div>
                    <div>
                        {booknow == true && (
                            <>
                                <a href={booknowlink} className="border-2 border-education-pink-barbie p-2 text-education-pink-barbie rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-pink-barbie duration-200">BOOK NOW <span aria-hidden="true">&rarr;</span></a>
                            </>
                        )}
                        {notifyme && (
                            <>
                                <a className="border-2 border-education-pink-barbie p-2 text-education-pink-barbie rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-pink-barbie duration-200" onClick={() => openModalNotify(date, name)}>NOTIFY ME <span aria-hidden="true">&rarr;</span></a>
                            </>
                        )}
                        {waitinglist && (
                            <>
                                <a className="border-2 border-education-pink-barbie p-2 text-education-pink-barbie rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-pink-barbie duration-200" onClick={() => openModalWait(date, name)}>WAITING LIST <span aria-hidden="true">&rarr;</span></a>
                            </>
                        )}
                    </div> */}
                    <div class="justify-center items-baseline">
                        {promo ? (
                            <>
                                <span className="line-through mr-2 text-3xl font-extrabold">£{cost}</span>
                                <span className="mr-2 text-5xl font-extrabold">£{promo}</span>
                            </>
                        ) : (
                            <span class="mr-2 text-5xl font-extrabold">£{cost}</span>
                        )}
                        <span class="text-gray-500 dark:text-gray-400">/child</span>
                        {promo ? (
                            <>
                                <br />
                                {/* <span className="mr-2 text-md text-education-main pt-4 font-extrabold">20% OFF available until July 20th</span> */}
                            </>
                        ) : null}
                    </div>
                    {page === "home" && <a href={courseinfo} class="text-education-main-text border-education-main-text border-2 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-md uppercase rounded-lg text-sm px-5 py-2.5 text-center font-synonym_medium">Course info</a>}
                    <a href={booknowlink} class="text-white bg-education-main hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-md uppercase rounded-lg text-sm px-5 py-2.5 text-center font-synonym_medium">Book now</a>
                </div>
            </div>

            <div className={`z-0 grid md:hidden grid-cols-1 md:grid-cols-6 pl-5 mt-2 p-8 rounded-lg ${waitinglist || notifyme ? 'bg-education-white/80' : 'bg-education-white'}`}>
                <div className="md:col-span-3 flex items-center">
                    {icon && <div dangerouslySetInnerHTML={{ __html: icon }} />}
                    {iconhome && iconhome}
                    <div className="ml-4">
                        <h3 className="font-synonym_medium leading-5 tracking-wide text-2xl">
                            {name} {fullybooked && "- FULLY BOOKED"}
                        </h3>
                    </div>
                </div>

                <div className="md:col-span-3 mt-4 md:mt-0">
                    <div className="text-gray-500 font-synonym_regular text-md">
                        <div>
                            <span className="text-gray-800 font-synonym_medium">Age group</span> {agegroup}
                        </div>
                        <div className="mt-2">
                            <span className="text-gray-800 font-synonym_medium">Date</span> <span className="ml-4 text-gray-800">{date}</span>
                        </div>
                        <div className="mt-2">
                            <span className="text-gray-800 font-synonym_medium">Location</span> {location}
                        </div>
                        <div className="mt-2">
                            <span className="text-gray-800 font-synonym_medium">Duration</span> From {starttime} - {endtime}
                        </div>
                        <div className="mt-4">
                            {label === "cheaper" && (
                                <>
                                    <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Quick Session</span>
                                    <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Budget-Friendly</span>
                                    <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Compact Workshop</span>
                                </>
                            )}
                            {label === "longer" && (
                                <>
                                    <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">In-Depth Workshop</span>
                                    <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Full-Day</span>
                                    <span className="bg-gray-100 p-2 my-2 rounded-lg text-sm lowercase text-education-black">Comprehensive Experience</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="md:col-span-2 items-center justify-center grid grid-cols-1 text-center mt-4 md:mt-0">
                    <div className="justify-center items-baseline">
                        <span className="mr-2 text-5xl font-extrabold">£{cost}</span>
                        <span className="text-gray-500 dark:text-gray-400">/child</span>
                    </div>
                    {page === "home" && (
                        <a href={courseinfo} className="text-education-main-text border-education-main-text border-2 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-md uppercase rounded-lg text-sm px-5 py-2.5 text-center font-synonym_medium mt-4 md:mt-0">
                            Course info
                        </a>
                    )}
                    <a href={booknowlink} className="text-white bg-education-main hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-md uppercase rounded-lg text-sm px-5 py-2.5 text-center font-synonym_medium mt-4 md:mt-0">
                        Book now
                    </a>
                </div>
            </div>



            <ModalNotifyWorkshop
                isOpen={modalDataNotify.isOpen}
                onClose={closeModalNotify}
                workshopDate={modalDataNotify.dateWorkshop}
                workshop="build a rocket"
            />

            <ModalWaitinglist
                isOpen={modalDataWait.isOpen}
                onClose={closeModalWait}
                workshopDate={modalDataWait.dateWorkshop}
                workshop="build a rocket"
            />
        </>

    );
}

export default SessionBlock;
