import React, { Component, Layout } from 'react';
import { useEffect, useState } from 'react'
import Header from '../common/Header';
// import Banner from './Banner';
import Banner3 from './Banner3';
import Education from './Education';
import Contact from './Contact';
import Society from './Society';
import Loader from '../common/Loader';
import About from './About';
import Footer from '../common/Footer';
import { BiArrowFromBottom } from 'react-icons/bi'
import RegisterInterest from './RegisterInterest';
import Team from './Team';
import { TabTitle } from '../../utils/General';
// import BecomeAnAstronautHome from './BecomeAnAstronautHome';
import Events from './Events';
import Iframe from 'react-iframe'
import ClubHomepage from './ClubHomepage';

function HomeNew(props) {
	const [loading, setLoading] = useState(true);
	const [isVisible, setIsVisible] = useState(false)

	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true)
		} else {
			setIsVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}


	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility)
		return () => {
			window.removeEventListener('scroll', toggleVisibility)
		}
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, []);

	TabTitle("Louno Space | STEM Education for kids")

	return (
		<>
			<div className="isolate bg-[#fffefe] overflow-y-hidden overflow-hidden">
				{loading && <Loader />}
				{!loading && <Banner3 />}
				{/* {!loading && <About />} */}
				{/*{!loading && <Education />}*/}
				{/*{!loading && <Team />}*/}
				{/* {!loading && <Events />} */}
				{/* {!loading && <Contact />} */}
				{/*{!loading && <Society />}*/}
				{/*{!loading && <RegisterInterest />}*/}
				{!loading && <Footer />}
			</div>


			<div className="fixed bottom-2 right-2">
				<button
					type="button"
					onClick={scrollToTop}
					className={
						isVisible ? 'opacity-100 bg-education-main hover:border-2 hover:border-education-main hover:bg-louno-white focus:ring-education-main inline-flex items-center rounded-full p-3 text-louno-white hover:text-education-main shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 m-4 h-12 w-12' : 'opacity-0'
					}
				>
					<BiArrowFromBottom className="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
		</>
	);
}

export default HomeNew;
