import React, { useState } from 'react';
import Cookies from 'js-cookie';

const FeedbackModal = ({ handleClose, page }) => {
    // const handleClickOutside = (event) => {
    //     if (!event.target.closest('.feedback-modal')) {
    //         handleClose();
    //     }
    // };

    // // Function to handle "Esc" key press
    // const handleKeyPress = (event) => {
    //     if (event.key === 'Escape') {
    //         handleClose();
    //     }
    // };

    // useEffect(() => {
    //     // Add event listeners when the modal is mounted
    //     document.addEventListener('mousedown', handleClickOutside);
    //     document.addEventListener('keydown', handleKeyPress);

    //     // Clean up event listeners when the modal is unmounted
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []); // Empty dependency array to ensure this effect runs only once

    const [rating, setRating] = useState("");

    const handleRatingClick = (value) => {
        setRating(value);
    };

    const [checkboxChecked, setCheckboxChecked] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const [formData, setFormData] = useState({
        email: '',
        feedback: '',
        rating: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [message, setMessage] = useState('');

    const formv3 = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        if (!checkboxChecked) {
            setMessage('Please confirm your consent to proceed.');
            return; // Do not proceed with form submission
        }

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/ee32adb8-a85a-4a36-afec-f0793f0ea624';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                },
                {
                    objectTypeId: "0-1",
                    name: "feedback",
                    value: formData.feedback,
                },
                {
                    objectTypeId: "0-1",
                    name: "rating",
                    value: rating,
                },
                {
                    objectTypeId: "0-1",
                    name: "page_feedback",
                    value: page,
                },
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };


        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("Feedback sent. You're now in the draw for the giveaway. Good luck! 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-lg z-10">
            <div className="rounded-xl p-8 relative feedback-modal-content">
                <div className="popup-content md:w-full w-3/4 justify-center items-center mx-auto">
                    <card class="flex flex-col max-w-xl p-8 mx-auto space-y-8 text-gray-900 bg-white shadow-xl rounded-xl" >
                        <div className="grid grid-cols-3">
                            <div className="col-span-2">
                                <h1 class="mb-2 text-xl font-semibold ">Give Feedback</h1>
                                <p class="text-xs font-semibold">
                                    For a chance to win a free workshop session 🚀
                                </p>
                            </div>
                            <div className="col-span-1">
                                <div className="flex justify-end">
                                    <button className="text-gray-600 hover:text-red-500" onClick={handleClose}>
                                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <form className="w-full mt-16" onSubmit={formv3}>
                            <ul class="flex justify-center gap-2">
                                <li onClick={() => handleRatingClick("terrible")}
                                    className={`wq-32 px-4 py-3 rounded text-center group ${rating === "terrible" ? 'border-2 border-education-pink-barbie' : 'border border-[#666E9E] hover:scale-105 hover:border-education-pink-barbie'}`}
                                >
                                    <svg class="w-8 h-8 mx-auto mb-1" role="img Terrible" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm0-144c-33.6 0-65.2 14.8-86.8 40.6-8.5 10.2-7.1 25.3 3.1 33.8s25.3 7.2 33.8-3c24.8-29.7 75-29.7 99.8 0 8.1 9.7 23.2 11.9 33.8 3 10.2-8.5 11.5-23.6 3.1-33.8-21.6-25.8-53.2-40.6-86.8-40.6zm-48-72c10.3 0 19.9-6.7 23-17.1 3.8-12.7-3.4-26.1-16.1-29.9l-80-24c-12.8-3.9-26.1 3.4-29.9 16.1-3.8 12.7 3.4 26.1 16.1 29.9l28.2 8.5c-3.1 4.9-5.3 10.4-5.3 16.6 0 17.7 14.3 32 32 32s32-14.4 32-32.1zm199-54.9c-3.8-12.7-17.1-19.9-29.9-16.1l-80 24c-12.7 3.8-19.9 17.2-16.1 29.9 3.1 10.4 12.7 17.1 23 17.1 0 17.7 14.3 32 32 32s32-14.3 32-32c0-6.2-2.2-11.7-5.3-16.6l28.2-8.5c12.7-3.7 19.9-17.1 16.1-29.8z"></path>
                                    </svg>
                                    <span class={`text-xs font-medium ${rating === "terrible" ? 'text-education-pink-barbie' : 'text-gray-600 group-hover:text-education-pink-barbie'}`}>Terrible</span>
                                </li>

                                <li onClick={() => handleRatingClick("bad")}
                                    className={`w-32 px-4 py-3 rounded text-center group ${rating === "bad" ? 'border-2 border-education-pink-barbie' : 'border border-[#666E9E] hover:scale-105 hover:border-education-pink-barbie'}`}
                                >
                                    <svg class="w-8 h-8 mx-auto mb-1" role="img Bad" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                        <path fill="currentColor"
                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z">
                                        </path>
                                    </svg>
                                    <span class={`text-xs font-medium ${rating === "bad" ? 'text-education-pink-barbie' : 'text-gray-600 group-hover:text-education-pink-barbie'}`}>Bad</span>
                                </li>
                                <li onClick={() => handleRatingClick("okay")}
                                    className={`w-32 px-4 py-3 rounded text-center group ${rating === "okay" ? 'border-2 border-education-pink-barbie' : 'border border-[#666E9E] hover:scale-105 hover:border-education-pink-barbie'}`}
                                >
                                    <svg class="w-8 h-8 mx-auto mb-1" role="img Okay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                        <path fill="currentColor"
                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm8 144H160c-13.2 0-24 10.8-24 24s10.8 24 24 24h176c13.2 0 24-10.8 24-24s-10.8-24-24-24z">
                                        </path>
                                    </svg>
                                    <span class={`text-xs font-medium ${rating === "okay" ? 'text-education-pink-barbie' : 'text-gray-600 group-hover:text-education-pink-barbie'}`}>Okay</span>
                                </li>
                                <li onClick={() => handleRatingClick("good")}
                                    className={`w-32 px-4 py-3 rounded text-center group ${rating === "good" ? 'border-2 border-education-pink-barbie' : 'border border-[#666E9E] hover:scale-105 hover:border-education-pink-barbie'}`}
                                >
                                    <svg class="w-8 h-8 mx-auto mb-1" role="img Good" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                        <path fill="currentColor"
                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z">
                                        </path>
                                    </svg>
                                    <span class={`text-xs font-medium ${rating === "good" ? 'text-education-pink-barbie' : 'text-gray-600 group-hover:text-education-pink-barbie'}`}>Good</span>
                                </li>
                                <li onClick={() => handleRatingClick("amazing")}
                                    className={`w-32 px-4 py-3 rounded text-center group ${rating === "amazing" ? 'border-2 border-education-pink-barbie' : 'border border-[#666E9E] hover:scale-105 hover:border-education-pink-barbie'}`}
                                >
                                    <svg class="w-8 h-8 mx-auto mb-1" role="img Amazing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                        <path fill="currentColor"
                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm105.6-151.4c-25.9 8.3-64.4 13.1-105.6 13.1s-79.6-4.8-105.6-13.1c-9.8-3.1-19.4 5.3-17.7 15.3 7.9 47.2 71.3 80 123.3 80s115.3-32.9 123.3-80c1.6-9.8-7.7-18.4-17.7-15.3zm-227.9-57.5c-1 6.2 5.4 11 11 7.9l31.3-16.3 31.3 16.3c5.6 3.1 12-1.7 11-7.9l-6-34.9 25.4-24.6c4.5-4.5 1.9-12.2-4.3-13.2l-34.9-5-15.5-31.6c-2.9-5.8-11-5.8-13.9 0l-15.5 31.6-34.9 5c-6.2.9-8.9 8.6-4.3 13.2l25.4 24.6-6.1 34.9zm259.7-72.7l-34.9-5-15.5-31.6c-2.9-5.8-11-5.8-13.9 0l-15.5 31.6-34.9 5c-6.2.9-8.9 8.6-4.3 13.2l25.4 24.6-6 34.9c-1 6.2 5.4 11 11 7.9l31.3-16.3 31.3 16.3c5.6 3.1 12-1.7 11-7.9l-6-34.9 25.4-24.6c4.5-4.6 1.8-12.2-4.4-13.2z">
                                        </path>
                                    </svg>
                                    <span class={`text-xs font-medium ${rating === "amazing" ? 'text-education-pink-barbie' : 'text-gray-600 group-hover:text-education-pink-barbie'}`}>Amazing</span>
                                </li>
                            </ul>

                            <div class="">
                                <p class="text-sm font-semibold transition-all mt-6">
                                    What are the main reasons for you rating?
                                </p>
                                <textarea
                                    className="w-full p-2 mt-2 text-sm border-2 border-gray-400 rounded-lg outline-none resize-none focus:border-pink-700 placeholder:text-sm"
                                    onChange={handleInputChange}
                                    value={formData.feedback}
                                    name="feedback" // Make sure the name attribute is set to "feedback"
                                    placeholder="Enter your feedback here..."
                                ></textarea>


                                <p class="text-sm font-semibold transition-all mt-4">
                                    Email address <span className="text-xs">(to win a free workshop session)</span>
                                </p>
                                <input
                                    className="w-full p-2 mt-2 text-sm border-2 border-gray-400 rounded-lg outline-none resize-none focus:border-pink-700 placeholder:text-sm"
                                    type="email"
                                    name="email" // Make sure the name attribute is set to "email"
                                    onChange={handleInputChange}
                                    value={formData.email}
                                    placeholder="Enter your email address..."
                                />

                                <div class="mt-4">
                                    <div class="flex items-center mb-4">
                                        <input
                                            id="default-checkbox"
                                            type="checkbox"
                                            value=""
                                            name="checkbox"
                                            className="w-4 h-4 text-education-pink-barbie bg-gray-100 border-gray-300 rounded focus:ring-pink-500 dark:focus:ring-education-pink-barbie dark:ring-offset-gray-800 focus:ring-2"
                                            onChange={handleCheckboxChange}
                                            checked={checkboxChecked}
                                        />
                                        <label for="default-checkbox" class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300" required>I may be contacted about this feedback, and I confirm I want to take part in the giveaway. Check our <span
                                            class="font-bold text-education-pink-barbie cursor-pointer hover:text-pink-500"><a href="/policies">Privacy Policy</a></span></label>
                                    </div>
                                </div>
                                <span className="text-education-ming">{message}</span>


                                <div class="flex justify-end mt-6 space-x-4">
                                    <button type="submit"
                                        class="px-6 py-2.5 bg-education-pink-barbie text-sm font-medium text-white rounded-lg transition-all cursor-pointer hover:bg-pink-900">
                                        Submit
                                    </button>
                                    <button
                                        onClick={handleClose}
                                        className="px-6 py-2.5 text-sm font-medium text-gray-600 transition-all cursor-pointer hover:text-pink-400"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </card >
                </div >
            </div >
        </div>
    );
};

export default FeedbackModal;
