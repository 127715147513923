import React, { useEffect, useState, useMemo } from 'react';
// import { useParams } from 'react-router-dom';
import Loader from '../common/Loader';
import Header from '../common/Header';
import Footer from '../common/Footer';
import HeadTags from "../common/HeadTags";
import ContactUsModal from '../../utils/ContactUsModal'
import SessionBlock from '../homepage/SessionBlock'
import FeedbackButton from '../../utils/FeedbackButton'
// import AOS from 'aos';
// import "aos/dist/aos.css";

const WorkshopPage = ({ slug }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            // ReactGA.initialize('G-66C9LPYT24');
            // ReactGA.pageview(window.location.pathname + window.location.search);
            // Smartlook.init('14545ea10544e1c6c276274490b06623c48a6a74')
        }, 500);
    }, []);


    // ✅ Memoize the workshops array so it's not recreated on every render
    const workshops = useMemo(() => [
        {
            id: "build-a-rocket",
            title: "Build a Rocket",
            courseinfo: "/build-a-rocket",
            descriptionShort: 'Children will practice the engineering assembly process of a rocket, and will experience the launch adrenaline. Through this activity, they will develop the skills to build a small rocket capable of being launched, they will perform analysis, calculations and will compare their findings, exactly like a real engineer!',
            highlights: '<li>Taught by a real space engineer</li><li>Build, personalise and fly your rocket</li><li>Solve challenges and make new friends</li><li>Train your critical thinking and problem-solving skills</li><li>Receive a certificate of completion</li>',
            topics: '<li>What does a space engineer do?</li><li>What is a rocket?</li><li>Why do we go to space?</li><li>What is Gravity?</li><li>Comets, Asteroids, Planets, Moon and Black Holes</li><li>Can we build a time travel machine?</li><li>Build your rocket</li>',
            agegroup: "4 - 11",
            image1: "/assets/images/build_a_rocket_v.webp",
            image2: "/assets/images/build_a_rocket_h1.webp",
            image3: "/assets/images/build_a_rocket_h2.webp",
            image4: "/assets/images/840x1088.png",
            video: "https://www.youtube.com/embed/IubhmbiD2oY",
            videocover: "/assets/images/build_a_rocket_cover.png",
            cost: "35 - 55",
            icon: '<img width="50" height="50" src="https://img.icons8.com/ios/50/000000/rocket--v1.png" alt="Louno Space Workshop" />',
            sessions: []
        },
        {
            id: "astronomy-101",
            title: "Astronomy 101",
            courseinfo: "/astronomy-101",
            descriptionShort: 'Discover the WONDERS of the Solar System! In our captivating Astronomy 101 workshop, students will learn about planets, moons, and stars, crafting their very own model of the solar system. Plus, we will build a model of the Solar System together and... discuss the possibility of aliens in the Universe 🛸',
            highlights: '<li>Taught by a real space engineer</li><li>Build your own model of the Solar System</li><li>Solve challenges and make new friends</li><li>Train your critical thinking and problem solving skills</li><li>Receive a certificate of completion</li>',
            topics: '<li>What does a space engineer do?</li><li>What is the Universe?</li><li>Why do we go to space?</li><li>What is Gravity?</li><li>Comets, Asteroids, Planets, Moon and Black Holes</li><li>Can we build a time travel machine?</li><li>Do aliens exist?</li><li>Build a model of the Solar System</li>',
            agegroup: "4 - 9",
            image1: "/assets/images/astronomy_v.webp",
            image2: "/assets/images/astronomy_h.webp",
            image3: "/assets/images/astronomy_h2.webp",
            image4: "/assets/images/840x1088.png",
            video: "https://www.youtube.com/embed/TLydCDx5aJA?feature=share",
            videocover: "/assets/images/astronomy_cover.png",
            cost: "35 - £50",
            icon: '<img width="50" height="50" src="https://img.icons8.com/external-itim2101-lineal-itim2101/50/external-space-space-and-galaxy-itim2101-lineal-itim2101.png" alt="Louno Space Workshop" />',
            sessions: []
        },
    ], []); // ✅ Added empty dependency array to prevent re-creation

    const [workshop, setWorkshop] = useState({});

    useEffect(() => {
        // ✅ `workshops` is now stable and won't cause unnecessary re-renders
        const foundWorkshop = workshops.find(w => w.id === slug);
        setWorkshop(foundWorkshop || {});
    }, [slug, workshops]);



    const [isContactUsModalOpen, setContactUsModalOpen] = useState(false);

    const openContactUsModal = () => {
        setContactUsModalOpen(true);
    };

    const closeContactUsModal = () => {
        setContactUsModalOpen(false);
    };

    const [isOpenVideo, setIsOpenVideo] = useState(false);

    const openPopup = () => {
        setIsOpenVideo(true);
    };

    const closePopup = () => {
        setIsOpenVideo(false);
    };

    return (
        <>
            {!loading ?
                <>
                    <>
                        <HeadTags pageTitle={workshop.title} />
                        <div class="relative isolate overflow-hidden bg-education-white">
                            <Header />
                            <FeedbackButton page={slug} />
                            <div class="relative isolate overflow-hidden bg-white py-8 sm:py-12 mx-auto max-w-7xl">
                                <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3">
                                    <div className="gap-4">
                                        <img src={workshop.image1} className="hidden md:block rounded-xl p-2" alt="Louno Space Workshop" />
                                    </div>
                                    <div className="gap-4">
                                        <img src={workshop.image2} className="rounded-xl p-2" alt="Louno Space Workshop" />
                                        <img src={workshop.image3} className="hidden md:block rounded-xl p-2" alt="Louno Space Workshop" />
                                    </div>
                                    <div className="gap-4">
                                        {workshop.video === "" ? (
                                            <img src={workshop.image4} className="hidden md:block rounded-xl p-2" alt="Louno Space Workshop" />
                                        ) : (
                                            <div style={{ '--image-url': `url(${workshop.videocover})` }} className='bg-[image:var(--image-url)] bg-cover relative md:w-[405px] md:h-[520px] overflow-hidden rounded-xl mt-2'>
                                                <div aria-hidden="true" class="hidden md:grid absolute -top-10 -left-10 md:-top-2 md:-left-2 w-[50px] h-[50px] md:w-[80px] md:h-[80px] bg-education-main/70 rounded-xl place-items-center">
                                                    <svg class="w-[32px] h-[64px] md:w-[32px] md:h-[32px]" viewBox="0 0 64 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M60 29.0718C65.3333 32.151 65.3333 39.849 60 42.9282L12 70.641C6.66668 73.7202 1.16801e-05 69.8712 1.19493e-05 63.7128L1.4372e-05 8.28719C1.46412e-05 2.12878 6.66668 -1.72022 12 1.35899L60 29.0718Z" fill="white" />
                                                    </svg>
                                                </div>
                                                <div aria-hidden="true" class="absolute md:w-[350px] h-[350px] bg-education-main rounded-full top-20 -right-64"></div>
                                                <div class="absolute right-8 inset-y-0 grid items-center animate-pulse">
                                                    <button onClick={openPopup} className="w-12 h-12 bg-education-main rounded-full ring-4 ring-white grid place-items-center hover:bg-education-main/60 transition">
                                                        <span className="sr-only">Watch the video</span>
                                                        <svg className="ml-1 w-4" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z" fill="white" />
                                                        </svg>
                                                    </button>

                                                </div>
                                                <div class="relative p-8 flex flex-col justify-end h-full space-y-4 w-2/3">
                                                    <p class="uppercase font-semibold text-xs text-education-main/80 tracking-wider">USE SPACE TO INSPIRE</p>
                                                    <h1 class="text-xl md:text-4xl font-extrabold text-white font-chillax_medium">Unleash your child's inner astronaut</h1>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div class="px-6 lg:px-10 grid grid-cols-1 md:grid-cols-3 pt-16 gap-x-8">
                                    <div className="col-span-2">
                                        <h1 className="text-4xl font-chillax_medium">{workshop.title}
                                            <span className="ml-4 text-lg">for children aged {workshop.agegroup}</span>
                                            <div className="flex">
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" alt="Louno Space Workshop" />
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" alt="Louno Space Workshop" />
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" alt="Louno Space Workshop" />
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" alt="Louno Space Workshop" />
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" alt="Louno Space Workshop" />
                                            </div>
                                        </h1>

                                        <p className="font-synonym_regular mt-4" dangerouslySetInnerHTML={{ __html: workshop.descriptionShort }}></p>
                                        <h4 className="text-md pt-8 font-synonym_medium">Highlights</h4>
                                        <p className="font-synonym_regular mt-2">
                                            <ul className="list-disc ml-4" dangerouslySetInnerHTML={{ __html: workshop.highlights }}>

                                            </ul>
                                        </p>
                                        <hr className="my-8" />
                                        <h3 className="text-2xl font-chillax_medium">Topics</h3>
                                        <p className="font-synonym_regular mt-2">
                                            <ul className="list-disc ml-4" dangerouslySetInnerHTML={{ __html: workshop.topics }}>

                                            </ul>
                                        </p>
                                        <hr className="my-8" />
                                        <h3 className="text-2xl font-chillax_medium">General Info</h3>
                                        <p className="font-synonym_regular mt-2">
                                            <ul className="list-disc ml-4">
                                                <li>Our weekend classes run from 9 am to 2 pm or from 11:30 am to 3:30 pm</li>
                                                <li>Our sessions are completely hands-on and project-based</li>
                                                <li>Your children will be grouped and taught by ages/abilities and will be assisted throughout the day</li>
                                                <li>10% discount if you bring a sibling or a friend, add code "MOONLANDING" when booking</li>
                                                <li>Our tutors are fully vaccinated and have enhanced DBS checks in place</li>
                                                <li>We have a 10:1 children/tutor ratio</li>
                                                <li>All our workshops are fully risk assessed</li>
                                                <li>We provide fresh fruit, biscuits and squash. Please, bring packed lunch or snacks if needed</li>
                                                <li>Please, let us know of any allergies</li>
                                                <li>We welcome children from any backgrounds, and we adjust our workshops if needed. If you children have a disability, please let us know.</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="col-span-1 md:border-l mt-16 md:mt-0 md:pl-8">
                                        <h2 className="text-3xl text-education-black">£{workshop.cost} <span className="text-xl">per child</span></h2>
                                        <div className="mt-4">
                                            <div className="mb-16">
                                                <a href="#sessions"
                                                    className="w-full bg-education-main p-4 rounded-lg block text-education-white text-center uppercase">Book
                                                    now</a>
                                                <h4 className="text-sm font-synonym_medium mt-2 text-gray-700">10% OFF if you bring a sibling or a friend</h4>
                                            </div>
                                            <div className="mb-16">
                                                <h4 className="text-lg font-synonym_medium mt-2 text-education-ming ">If you have any questions</h4>
                                                <a href="/faqs"
                                                    className="w-full bg-education-ming p-4 rounded-lg block mb-4 text-education-white text-center uppercase"
                                                >View FAQ </a>
                                                <button type="button"
                                                    className="w-full bg-education-ming p-4 rounded-lg block text-education-white text-center uppercase"
                                                    onClick={openContactUsModal}>Enquire </button>
                                            </div>
                                            <div className="">
                                                <h4 className="text-lg font-synonym_medium mt-2 text-education-pink-barbie">Have us for your space birthday party!</h4>
                                                <a href="/birthday-party-kids"
                                                    className="w-full bg-education-pink-barbie p-4 rounded-lg block text-education-white text-center uppercase">Plan now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="text-lg text-gray-900 text-center mt-10 font-synonym_medium">
                                    Call us for any assistance on 07873 62 14 02 or email us at <a href="mailto:hi@louno.space" className="text-education-main">hi@louno.space</a>
                                </p>
                            </div>
                            <div class="relative isolate overflow-hidden bg-education-ming py-12 sm:py-24 mx-auto" id="sessions">
                                <div className="mx-auto max-w-7xl">
                                    <div class="px-6 lg:px-8">
                                        <div class="mx-auto lg:mx-0 grid grid-cols-5 w-full">
                                            <div className="col-span-4">
                                                <h1 class="text-4xl font-bold tracking-tight text-education-main sm:text-5xl font-chillax_semibold">
                                                    Find a session</h1>
                                                <p class="mt-6 text-lg leading-8 text-education-white max-w-2xl font-synonym_medium">Ready to launch
                                                    your child's curiosity? Explore our upcoming sessions and secure a spot for your little
                                                    explorer.</p>
                                            </div>
                                            <div className="col-span-1">
                                                <h1 class="text-4xl font-bold tracking-tight text-white/70 sm:text-5xl"><img
                                                    src="../assets/images/icon-y.png" className="w-32 mt-4" alt="Louno Space Workshop" /></h1>
                                            </div>
                                        </div>
                                        <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none font-chillax_regular">
                                            <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space sm:grid-cols-1 lg:gap-x-10">
                                                {workshop.sessions && workshop.sessions.length > 0 ? (
                                                    workshop?.sessions.map((session, index) => (
                                                        <SessionBlock
                                                            key={index}
                                                            icon={workshop.icon}
                                                            name={workshop.title}
                                                            agegroup={workshop.agegroup}
                                                            date={session.date}
                                                            location={session.location}
                                                            starttime={session.starttime}
                                                            endtime={session.endtime}
                                                            notifyme={session.notifyme}
                                                            waitinglist={session.waitinglist}
                                                            fullybooked={session.fullybooked}
                                                            booknow={session.booknow}
                                                            booknowlink={session.booknowlink}
                                                            courseinfo={workshop.courseinfo}
                                                            label={session.label}
                                                            cost={session.cost}
                                                            promo={session.promo}
                                                        />
                                                    ))
                                                ) : (
                                                    <div className="grid grid-cols-1 md:grid-cols-1 pl-5 mt-2 p-8 bg-white rounded-lg">
                                                        <div className="text-center text-xl">
                                                            We currently don't have available sessions.
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                    {isOpenVideo && (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                            <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50" onClick={closePopup}></div>
                            <div className="relative bg-white rounded-lg p-8 w-full md:w-[70%]">
                                <button onClick={closePopup} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
                                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                                <div className="flex justify-center items-center aspect-w-16 aspect-h-9 max-h-[80vh] overflow-hidden">
                                    <iframe src={workshop.video} width="100%" className="w-full aspect-video rounded-2xl" frameborder="0" allowfullscreen title="Louno Space Workshop"></iframe>
                                </div>
                            </div>
                        </div>


                    )}
                    <ContactUsModal isOpen={isContactUsModalOpen} onClose={closeContactUsModal} page="build-a-rocket" />
                    <Footer />
                </>
                : <Loader />
            }
        </>
    );
}

export default WorkshopPage;
