import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Homepage from './components/homepage/Homepage';
// import Home from './components/homepage/Home';
import ErrorPage from './components/common/ErrorPage';
import Policies from './components/common/Policies';
import CookieConsent from "react-cookie-consent";
// import SpaceCalendar from './components/pages/spaceCalendar'; // do again
import OurEducation from './components/pages/OurEducation';

import OurWayToInspire from './components/pages/OurWayToInspire';
// import Linktree from './components/pages/Linktree';
import Contact from './components/pages/Contact';
// import Nova from './components/nova/Nova';
import MetaPixel from './utils/MetaPixel';
import Smartlook from './utils/Smartlook';
import HSTracker from './utils/HSTracker';
import GoogleTag from './utils/GoogleTag';
import DiscountModal from './utils/DiscountModal';
import LinkedInTag from './utils/LinkedInTag'
import HomeNew from './components/homepage/HomeNew';
import Build0GLab from './components/homepage/Build0GLab';
import Hotjar from '@hotjar/browser';
import Webinar from './components/pages/Webinar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ThankYou from './components/pages/ThankYou';
import BDay from './components/pages/bday';
import BritishScienceWeek from './components/pages/BritishScienceWeek'
import SpaceToInspire from './components/pages/SpaceToInspire';
import WorkshopPage from './components/pages/WorkshopPage';
import FAQs from './components/pages/FAQs';
import Test from './components/pages/Test';
import Challenge from './components/challenge/Challenge';
import ClubHomepage from './components/homepage/ClubHomepage';
import Partner from './components/pages/Partner';
import SatelliteBuilder from './components/pages/BuildYourSatellite';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const siteId = 3821016;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // ReactGA.initialize('G-66C9LPYT24');
      // ReactGA.pageview(window.location.pathname + window.location.search);
      // Smartlook.init('14545ea10544e1c6c276274490b06623c48a6a74')
    }, 500);
  }, []);

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '1001303114289114',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v18.0'
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }, []);

  return (
    <>
      {/* <CookieConsent style={{ background: "#232338" }} buttonStyle={{ color: "#232338", background: "#F48B00" }} location="top">This website uses cookies to enhance the user experience.</CookieConsent> */}
      <MetaPixel />
      <Smartlook />
      <HSTracker />
      <GoogleTag />
      {/* <DiscountModal /> */}
      <LinkedInTag />
      {/* <OfferCountdown /> */}
      <DndProvider backend={HTML5Backend}>

        <BrowserRouter basename="/">
          <Routes>
            {/* <Route path="/" element={<HomeNew />} /> */}
            <Route path="/build-0g-lab" element={<Build0GLab />} />
            <Route path="/webinar" element={<Webinar />} />
            <Route path="/policies" element={<Policies />} />
            {/* <Route path="/linktree" element={<Linktree />} /> */}
            <Route path="/our-education" element={<OurEducation />} />
            {/* <Route path="/our-way" element={<OurWayToInspire />} /> */}

            <Route path="/birthday-party-kids" element={<BDay />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/british-science-week" element={<BritishScienceWeek />} />
            <Route path="/space-to-inspire" element={<SpaceToInspire />} />
            <Route path="/build-a-rocket" element={<WorkshopPage slug="build-a-rocket" />} />
            <Route path="/astronomy-101" element={<WorkshopPage slug="astronomy-101" />} />
            <Route path="/faqs" element={<FAQs />} />

            <Route path="*" element={<ErrorPage />} />
            {/* <Route path="/nova" element={<Nova />} /> */}
            <Route path="/thankyou" element={<ThankYou />} />

            <Route path="/challenge" element={<Challenge />} />

            <Route path="/" element={<ClubHomepage />} />
            <Route path="/partner" element={<Partner />} />

            <Route path="/build-your-satellite" element={<SatelliteBuilder />} />
            <Route path="/bys" element={<SatelliteBuilder />} />
          </Routes>
        </BrowserRouter>
      </DndProvider>
    </>
  );
};

export default App;